export default class Weather {

    constructor(endpointUrl, defaultCityId, cityPath, staticLPCDNUrl) {

        if (!endpointUrl) {
            throw new TypeError("endpointUrl must be a non-empty string");
        }

        if (!cityPath) {
            throw new TypeError("cityPath must be a non-empty string");
        }

        const WEATHER_BOX_SELECTOR = ".weatherWidget";
        const WEATHER_LINK_SELECTOR = "a";
        const WEATHER_CITY_SELECTOR = ".weatherWidget__city";
        const WEATHER_ICON_SELECTOR = ".weatherWidget__weatherIcon img";
        const WEATHER_TEMPERATURE_SELECTOR = ".weatherWidget__temperature";

        const boxNode = document.querySelector(WEATHER_BOX_SELECTOR);

        if (boxNode) {
            try {
                endpointUrl = endpointUrl + defaultCityId;

                fetch(endpointUrl)
                    .then(response => {
                        if (response.status === 200) {
                            return response.json();
                        } else {
                            return null;
                        }
                    })
                    .then(responseData => {
                        if (responseData !== null) {
                            const linkNode = boxNode && boxNode.querySelector(WEATHER_LINK_SELECTOR);
                            const cityNode = boxNode && boxNode.querySelector(WEATHER_CITY_SELECTOR);
                            const iconNode = boxNode && boxNode.querySelector(WEATHER_ICON_SELECTOR);
                            const temperatureNode = boxNode && boxNode.querySelector(WEATHER_TEMPERATURE_SELECTOR);
                            (linkNode) && (linkNode.href = cityPath + defaultCityId);
                            (cityNode && responseData.nameFr) && (cityNode.innerHTML = responseData.nameFr);
                            (iconNode && responseData.current.icon) && (iconNode.src = staticLPCDNUrl + "/lapresse/img/meteo/icone_une/" + responseData.current.icon + ".png");
                            (iconNode) && (iconNode.alt = "météo " + cityNode.innerHTML);
                            (temperatureNode && responseData.current.temperature !== undefined) && (temperatureNode.innerHTML = responseData.current.temperature + "&deg;C");

                            boxNode.querySelector(".weatherWidget__weatherIcon").classList.remove("display-none");
                        }
                    })
                    .catch(error => {
                        console.warn("Weather => Error %o while fetching %o", error, endpointUrl);
                    });
            } catch (error) {
                console.warn("Weather => Error %o while fetching %o", error, endpointUrl);
            }
        }
    }
}
